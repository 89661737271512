import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
	dateFormatter,
	buttonsStyle,
	imageFormatter,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getGroups, deleteGroup } from "../../../../api/group";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { getGames } from "../../../../api/game";

function getData(groups, games) {
	let data = [];
	for (let i = 0; i < groups.length; ++i) {
		const elem = {};
		elem.name = groups[i].fullName;
		elem.image = groups[i].image;
		elem.tableNum = groups[i].tableNum;
		elem.game = groups[i].game
			? games.find((cat) => cat._id === groups[i].game)?.fullName
			: "---";
		elem.createdAt = groups[i].createdAt;
		elem.id = groups[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function GroupsPage() {
	const [data, setData] = useState([]);
	const [groupId, setGroupId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-group/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setGroupId(cell);
							setOpenConfirmDialog(true);
						}}
					>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: "image", text: "", formatter: imageFormatter },
		{ dataField: "name", text: "Full name", sort: true },
		{ dataField: "tableNum", text: "Table", sort: true },
		{ dataField: "game", text: "Game", sort: true },
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getGroups()
			.then((res) => {
				if (res.status === 200) {
					getGames()
						.then((respGames) => {
							if (respGames.status === 200) {
								setData(getData(res.data, respGames.data));
								setRefresh(false);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: "Could not get games.",
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get groups.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Groups list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-group")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={"Are you sure you want to remove this group?"}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteGroup(groupId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Group removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove group.",
									});
								});
						}}
					>
						{"This will erase all their members and their answers"}
					</ConfirmDialog>
				</CardBody>
			</Card>
		</>
	);
}
