import { /* baseClient, */ authClient, API } from "../../utils/index";

// Get all answers
export const getAnswers = () => {
	return authClient().get(`${API}/answer`);
};

// Get the specified answer
export const getAnswerById = (id) => {
	return authClient().get(`${API}/answer/${id}`);
};
// Get the specified answer by quiz
export const getAnswerByQuiz = (id) => {
	return authClient().get(`${API}/answer/quiz/${id}`);
};

// Get scenario answers
export const getAnswersByScenario = (id) => {
	return authClient().get(`${API}/answer/scenario/${id}`);
};
