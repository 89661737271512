import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import EditGamesPage from "./modules/ContentManager/games/EditGamesPage";
import GamesPage from "./modules/ContentManager/games/GamesPage";
import RankingGamesPage from "./modules/ContentManager/games/RankingGamesPage";
import EditGroupsPage from "./modules/ContentManager/groups/EditGroupsPage";
import GroupsPage from "./modules/ContentManager/groups/GroupsPage";
import EditParticipantsPage from "./modules/ContentManager/participants/EditParticipantsPage";
import ParticipantsPage from "./modules/ContentManager/participants/ParticipantsPage";
import QuizzesPage from "./modules/ContentManager/quizzes/QuizzesPage";
import ViewQuizPage from "./modules/ContentManager/quizzes/ViewQuizPage";
import ScenesPage from "./modules/ContentManager/scenes/ScenesPage";
import ViewScenePage from "./modules/ContentManager/scenes/ViewScenePage";
import { DashboardPage } from "./pages/DashboardPage";
import { ResultsPage } from "./pages/ResultsPage";

export default function BasePage() {
	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */}
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />
			<ContentRoute path="/results" component={ResultsPage} />
			{/* Administrators */}
			<ContentRoute from="/admins" component={AdminsPage} />
			<ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
			{/* Groups */}
			<ContentRoute from="/groups" component={GroupsPage} />
			<ContentRoute from="/edit-group/:id?" component={EditGroupsPage} />
			{/* Games */}
			<ContentRoute from="/games" component={GamesPage} />
			<ContentRoute from="/edit-game/:id?" component={EditGamesPage} />
			<ContentRoute
				from="/ranking-game/:id?"
				component={RankingGamesPage}
			/>
			{/* Participants */}
			<ContentRoute from="/participants" component={ParticipantsPage} />
			<ContentRoute
				from="/edit-participant/:id?"
				component={EditParticipantsPage}
			/>
			{/* Scenes */}
			<ContentRoute from="/scenarios" component={ScenesPage} />
			<ContentRoute from="/scenario/:id" component={ViewScenePage} />
			{/* Quizzes */}
			<ContentRoute from="/quizzes" component={QuizzesPage} />
			<ContentRoute from="/quiz/:id" component={ViewQuizPage} />
			<Redirect to="/error" />
		</Switch>
	);
}
