import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getGroupsByGame } from "../../../../api/group";
import { alertError } from "../../../../utils/logger";
import RankingDialog from "../../../components/dialogs/RankingDialog";
import Table, {
	booleanFormatter,
	imageFormatter,
	stampsFormatter,
	timeFormatter,
} from "../../../components/tables/table";

function getData(groups) {
	let data = [];
	for (let i = 0; i < groups.length; ++i) {
		const elem = {};
		elem.group = groups[i].fullName;
		elem.image = groups[i].image;
		elem.tableNum = groups[i].tableNum;
		elem.score = groups[i].totalScore;
		elem.time = groups[i].time;
		elem.timeAchieveStamps = groups[i].timeAchieveStamps;
		elem.finished = groups[i].finished;
		elem.id = groups[i]._id;
		data = data.concat(elem);
	}

	return data;
}

export default function RankingGamesPage() {
	const [data, setData] = useState([]);
	const [dataList, setDataList] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [openRankingDialog, setOpenRankingDialog] = useState(null);
	const gameId = useParams().id;

	const columns = [
		{
			dataField: "image",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: imageFormatter,
		},
		{
			dataField: "group",
			text: "Group",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "tableNum",
			text: "Table",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "score",
			text: "Stamps",
			headerAlign: "center",
			align: "center",
			formatter: stampsFormatter,
			sort: true,
		},
		{
			dataField: "time",
			text: "Total time",
			headerAlign: "center",
			align: "center",
			formatter: timeFormatter,
			sort: true,
		},
		{
			dataField: "timeAchieveStamps",
			text: "Last stamp attempt",
			headerAlign: "center",
			align: "center",
			formatter: timeFormatter,
			sort: true,
		},
		{
			dataField: "finished",
			text: "Finished",
			headerAlign: "center",
			align: "center",
			formatter: booleanFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getGroupsByGame(gameId)
			.then((res) => {
				if (res.status === 200) {
					setDataList(getData(res.data));
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get ranking.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Ranking">
					<CardHeaderToolbar>
						<button
							className={
								"btn btn-primary font-weight-bold my-3 align-self-center"
							}
							onClick={() => setOpenRankingDialog(true)}
						>
							Ranking Final
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={dataList} columns={columns} />
				</CardBody>
			</Card>
			<RankingDialog
				title={"Ranking final"}
				open={openRankingDialog}
				setOpen={setOpenRankingDialog}
				data={data}
			/>
		</>
	);
}
