import { Button, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import { getGameById, getGames, updateGame } from "../../api/game";
import { getGroups, getGroupsByGame } from "../../api/group";
import { getParticipants } from "../../api/participant";
import { alertError, alertSuccess } from "../../utils/logger";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";

function getAdminData(games, groups, participants) {
	let data = [];
	data.numGames = games.length;
	data.numGroups = groups.length;
	data.numParticipants = participants.length;
	return data;
}

function getModeratorData(groups, participants) {
	let data = [];
	let numParticipants = 0;
	participants.forEach((part) => {
		if (groups.some((gr) => gr._id === part.group)) numParticipants += 1;
	});
	data.numGroups = groups.length;
	data.numParticipants = numParticipants;
	return data;
}

function getData(game) {
	let data = [];
	return data;
}

export function DashboardPage() {
	const [data, setData] = useState([]);
	const [game, setGame] = useState(null);
	const [active, setActive] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const isModerator = user?.game ? true : false;

	useEffect(() => {
		getParticipants()
			.then((resParticipants) => {
				if (resParticipants.status === 200) {
					if (isModerator) {
						getGameById(user?.game)
							.then((resGame) => {
								if (resGame.status === 200) {
									getGroupsByGame(user?.game)
										.then((resGroups) => {
											if (resGroups.status === 200) {
												setGame(resGame.data);
												setActive(resGame.data.active);
												setData(
													getModeratorData(
														resGroups.data,
														resParticipants.data
													)
												);
												setTableData(
													getData(resGame.data)
												);
												setRefresh(false);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not get groups.",
											});
										});
								}
							})
							.catch((error) => {
								alertError({
									error: error,
									customMessage: "Could not get game.",
								});
							});
					} else {
						getGroups()
							.then((resGroups) => {
								if (resGroups.status === 200) {
									getGames()
										.then((resGames) => {
											if (resGames.status === 200) {
												setData(
													getAdminData(
														resGames.data,
														resGroups.data,
														resParticipants.data
													)
												);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not get games.",
											});
										});
								}
							})

							.catch((error) => {
								alertError({
									error: error,
									customMessage: "Could not get groups.",
								});
							});
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get participants.",
				});
			});
	}, [refresh]);

	function handleGameStatus() {
		setActive((active) => !active);
		setGame({ ...game, active: !active });
		updateGame(game._id, { ...game, active: !active })
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Success!",
						customMessage: !game.active
							? "Game Started"
							: "Game Ended",
					});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not start the game.",
				});
			});
	}

	return (
		<>
			<Card>
				<CardHeader
					title={
						game?.fullName != null ? game?.fullName : "Statistics"
					}
				/>
				<CardBody>
					<div>
						<div className="row justify-content-center">
							{!isModerator && (
								<div className="col-12 col-md-4 text-center my-2">
									<div class="card">
										<div class="card-body">
											<h5 class="card-title">
												Total games
											</h5>
											<div className="contentDash">
												{data.numGames}
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="col-12 col-md-4 text-center my-2">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">Total groups</h5>
										<div className="contentDash">
											{data.numGroups}
										</div>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-4 text-center my-2">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">
											Total participants
										</h5>
										<div className="contentDash">
											{data.numParticipants}
										</div>
									</div>
								</div>
							</div>
						</div>
						{isModerator && (
							<div className="row justify-content-center">
								<div className="col-12 col-md-3 text-center my-4">
									<div class="card blueBorder">
										<div class="card-body">
											<Tooltip title="Start">
												<Button
													onClick={() =>
														setOpenConfirmDialog(
															true
														)
													}
													className="contentDashB"
												>
													{active
														? "End Game"
														: "Start Game"}
												</Button>
											</Tooltip>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</CardBody>
				<ConfirmDialog
					title={`Are you sure you want to ${
						game?.active ? "end" : "start"
					} this game?`}
					open={openConfirmDialog}
					setOpen={setOpenConfirmDialog}
					onConfirm={handleGameStatus}
				/>
			</Card>
		</>
	);
}
