export function getKeyByValue(object, value) {
	return Object.keys(object).find((key) => object[key] === value);
}

export const delay = (n) => new Promise((r) => setTimeout(r, n * 1000));

export function formatTime(time) {
	if (!time) return ` 0' 00"`;
	else
		return `${Math.floor(time / 60)}' ${
			time % 60 < 10 ? "0" + (time % 60) : time % 60
		}"`;
}
