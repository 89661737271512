import { FormHelperText } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Select from "react-select";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import { getAnswers } from "../../api/answer";
import { getGames } from "../../api/game";
import { getGroupsByGame } from "../../api/group";
import { getQuizzes } from "../../api/quiz";
import { getScenes } from "../../api/scene";
import { alertError } from "../../utils/logger";
import {
	QuizResultsTable,
	ResultsTable,
	resultFormatter,
} from "../components/tables/table";

function getGameQuizzesData(groups, scenarios, quizzes, answers) {
	let data = [];
	scenarios.forEach((scenario) => {
		let quizzesRes = [];
		quizzes
			.filter(
				(quiz) => quiz.scenario._id === scenario._id && !quiz.supertest
			)
			.forEach((quiz) => {
				let quizRes = {};
				groups.forEach((group, indexGroup) => {
					let answer = answers.find(
						(answer) =>
							answer.quiz === quiz._id &&
							answer.group === group._id
					);
					if (answer) {
						quizRes[`group${indexGroup + 1}`] = {
							pts: answer.score,
							time: answer.time,
							name: group.fullName,
						};
						quizRes[`group${indexGroup + 1}`].correct =
							answer.score === 1;
					}
				});
				quizRes.quiz = quiz.fullName;
				quizzesRes.push(quizRes);
			});
		let elem = { scenario: scenario.fullName, results: quizzesRes };
		data.push(elem);
	});
	return data;
}

function getGameData(groups, scenarios, answers) {
	let data = [];

	scenarios.forEach((scenario, indexScenario) => {
		let elem = {};
		elem.scenario = `Scenario ${indexScenario + 1}`;
		elem.scenarioName = scenario.fullName;
		groups.forEach((group, indexGroup) => {
			let groupAnswers = answers.filter((answer) => {
				return (
					answer.scenario === scenario._id &&
					answer.group === group._id
				);
			});
			let result = null;
			if (groupAnswers.length)
				result = groupAnswers?.reduce((a, b) => ({
					score: a.score + b.score,
					time: a.time + b.time,
				}));
			if (result)
				elem[`group${indexGroup + 1}`] = {
					pts: result.score,
					time: result.time,
					name: group.fullName,
				};
		});
		data = data.concat(elem);
	});
	return data;
}

export function ResultsPage() {
	const [games, setGames] = useState([]);
	const [groups, setGroups] = useState([]);
	const [quizzes, setQuizzes] = useState([]);
	const [answers, setAnswers] = useState([]);
	const [scenarios, setScenarios] = useState([]);
	const [quizzesData, setQuizzesData] = useState([]);
	const [actualGame, setActualGame] = useState({ fullName: "" });
	const [data, setData] = useState([]);
	const [isExpanded, setIsExpanded] = useState(false);
	const [expandedRows, setExpandedRows] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function styleFormatter(cell, row) {
		if (cell) {
			let max = true;
			let min = true;
			for (let i = 1; i < 10; ++i) {
				if (
					row[`group${i}`]?.pts > cell.pts ||
					(row[`group${i}`]?.pts === cell.pts &&
						row[`group${i}`]?.time < cell.time)
				) {
					max = false;
				}

				if (
					row[`group${i}`]?.pts < cell.pts ||
					(row[`group${i}`]?.pts === cell.pts &&
						row[`group${i}`]?.time > cell.time)
				) {
					min = false;
				}
				if (!min && !max) {
					return;
				}
			}
			if (max) return { backgroundColor: "#c8e6c9" };
			else if (min) return { backgroundColor: "#e6c8c8" };
		}
	}

	function groupFormatter(column, colIndex) {
		return <div>{groups[colIndex - 1]?.fullName || column.text}</div>;
	}

	const handleOnExpandRow = (row, isExpand, rowIndex, e) => {
		if (isExpand) {
			setExpandedRows([...expandedRows, row.scenario]);
		} else {
			setExpandedRows(expandedRows.filter((x) => x !== row.scenario));
		}
	};

	const expandRow = {
		renderer: (row) => (
			<div style={{ margin: "10px" }}>
				<b>{`${row.scenarioName} | Quizzes Results`}</b>
				<QuizResultsTable
					data={
						quizzesData[row.scenario.match(/\d+/)[0] - 1]?.results
					}
					columns={quizColumns}
				/>
			</div>
		),
		showExpandColumn: true,
		onlyOneExpanding: true,
		expanded: expandedRows,
		onExpand: handleOnExpandRow,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			if (isAnyExpands) {
				return <b>-</b>;
			}
			return <b>+</b>;
		},
		expandColumnRenderer: ({ expanded }) => {
			if (expanded) {
				return <b>-</b>;
			}
			return <b>...</b>;
		},
	};

	const header = (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
			}}
		>
			<h4
				style={{
					textAlign: "center",
					color: "rgb(24, 28, 50)",
					border: "1px solid rgb(215, 218, 231)",
					borderWidth: "0px 0px 2px 0px",
					padding: "0.5em",
				}}
			>
				{"Results"}
			</h4>
		</div>
	);

	const columns = [
		{ dataField: "scenarioName", text: "Scenario" },
		{
			dataField: "group1",
			text: "Group 1",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group2",
			text: "Group 2",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group3",
			text: "Group 3",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group4",
			text: "Group 4",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group5",
			text: "Group 5",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group6",
			text: "Group 6",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group7",
			text: "Group 7",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group8",
			text: "Group 8",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group9",
			text: "Group 9",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
		{
			dataField: "group10",
			text: "Group 10",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
			style: styleFormatter,
		},
	];
	const quizColumns = [
		{ dataField: "quiz", text: "Quiz", headerAlign: "center" },
		{
			dataField: "group1",
			text: "Group 1",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group2",
			text: "Group 2",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group3",
			text: "Group 3",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group4",
			text: "Group 4",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group5",
			text: "Group 5",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group6",
			text: "Group 6",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group7",
			text: "Group 7",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group8",
			text: "Group 8",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group9",
			text: "Group 9",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
		{
			dataField: "group10",
			text: "Group 10",
			headerAlign: "center",
			headerFormatter: groupFormatter,
			formatter: resultFormatter,
		},
	];

	useEffect(() => {
		getGames().then((res) => {
			if (res.status === 200) {
				let games = res.data;
				setGames(res.data);

				if (user?.game) {
					let game = games.find((x) => x._id === user?.game);
					if (game) {
						setActualGame(game);
					}
				}
			}
		});
	}, []);

	useEffect(() => {
		if (actualGame?._id) {
			getGroupsByGame(actualGame._id)
				.then((resGroups) => {
					if (resGroups.status === 200) {
						let groups = resGroups.data;
						setGroups(groups);
						setQuizzesData(
							getGameQuizzesData(
								groups,
								scenarios,
								quizzes,
								answers
							)
						);
						setData(getGameData(groups, scenarios, answers));
						setRefresh(false);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get game groups.",
					});
				});
		}
	}, [actualGame]);

	useEffect(() => {
		getQuizzes()
			.then((res) => {
				if (res.status === 200) {
					setQuizzes(res.data);
					getAnswers()
						.then((res) => {
							if (res.status === 200) {
								setAnswers(res.data);
								getScenes()
									.then((res) => {
										if (res.status === 200) {
											setScenarios(res.data);
											getGames()
												.then((res) => {
													if (res.status === 200) {
														setGames(res.data);
														if (user?.game) {
															setActualGame(
																res.data.filter(
																	(elem) => {
																		return (
																			elem._id ===
																			user?.game
																		);
																	}
																)[0]
															);
														}
														setRefresh(false);
													}
												})
												.catch((error) => {
													alertError({
														error: error,
														customMessage:
															"Could not get games.",
													});
												});
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												"Could not get scenarios.",
										});
									});
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: "Could not get answers.",
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get quizzes.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader
					title={`${
						user?.game ? actualGame?.fullName : "Game results"
					}`}
				></CardHeader>
				<CardBody>
					{!user?.game && (
						<>
							<Select
								options={games?.map((game) => ({
									label: game.fullName,
									value: game._id,
								}))}
								value={games
									?.filter(
										(game) => game._id === actualGame._id
									)
									.map((game) => ({
										label: game.fullName,
										value: game._id,
									}))}
								onChange={(value) => {
									setActualGame(
										games.filter(
											(game) => game._id === value.value
										)[0]
									);
									setRefresh(true);
								}}
								isClearable
								isSearchable
								placeholder={`Select game...`}
								className="mb-3 mt-3"
							/>
							<FormHelperText>Select a Game</FormHelperText>
						</>
					)}
					{actualGame.fullName && (
						<ResultsTable
							data={data}
							columns={columns}
							header={header}
							expandRow={expandRow}
						/>
					)}
				</CardBody>
			</Card>
		</>
	);
}
