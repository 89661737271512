import { Button, Snackbar, Tooltip } from "@material-ui/core";
import { PlayArrow, Replay, Stop } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import PlayIcon from "@material-ui/icons/PlayArrowRounded";
import IpadIcon from "@material-ui/icons/TabletMacOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	deleteGame,
	getGames,
	resetGameStats,
	setDefaultGame,
	updateGame,
} from "../../../../api/game";
import { FRONT_URL } from "../../../../utils";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	booleanFormatter,
	buttonsStyle,
} from "../../../components/tables/table";

function getData(games) {
	let data = [];
	for (let i = 0; i < games.length; ++i) {
		const elem = {};
		elem.name = games[i].fullName;
		elem.numTables = games[i].numTables;
		elem.createdAt = games[i].createdAt;
		elem.default = games[i].default;
		elem.demo = games[i].demo;
		elem.data = games[i];
		elem.id = games[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function GamesPage() {
	const [data, setData] = useState([]);
	const [gameId, setGameId] = useState(null);
	const [selectedGame, setSelectedGame] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [copiedLink, setCopiedLink] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		const game = cell;

		return (
			<>
				{!cell.default && (
					<Tooltip title="Set Default">
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() => {
								setGameId(cell._id);
								setOpenConfirmDialog(1);
							}}
						>
							<PlayIcon />
						</Button>
					</Tooltip>
				)}
				<Tooltip title="Ranking">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>
							history.push("/ranking-game/" + cell._id)
						}
					>
						<EmojiEventsIcon />
					</Button>
				</Tooltip>
				<Tooltip title={game?.active ? "End" : "Start"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedGame(game);
							setOpenConfirmDialog(4);
						}}
					>
						{game?.active ? <Stop /> : <PlayArrow />}
					</Button>
				</Tooltip>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-game/" + cell._id)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Play">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							navigator.clipboard.writeText(
								`${FRONT_URL}?game=${cell._id}`
							);
							setCopiedLink(true);
						}}
					>
						<IpadIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Reset stats">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setGameId(cell._id);
							setOpenConfirmDialog(3);
						}}
					>
						<Replay />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setGameId(cell._id);
							setOpenConfirmDialog(2);
						}}
					>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	function defaultFormatter(cell) {
		return cell ? <div className="default-label">default</div> : "";
	}

	const columns = [
		{ dataField: "name", text: "Full name", sort: true },
		{
			dataField: "numTables",
			text: "Tables",
			headerAlign: "center",
			align: "center",
			sort: true,
		},
		{
			dataField: "demo",
			text: "Demo",
			headerAlign: "center",
			align: "center",
			sort: true,
			formatter: booleanFormatter,
		},
		{ dataField: "default", text: "Default", formatter: defaultFormatter },
		{
			dataField: "data",
			text: "",
			formatter: buttonFormatter,
			align: "end",
		},
	];

	useEffect(() => {
		getGames()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get games.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Games list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-game")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={"Are you sure you want to remove this game?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteGame(gameId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Game removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove game.",
									});
								});
						}}
					/>
					<ConfirmDialog
						title="Are you sure you want to set this game as default?"
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							setDefaultGame(gameId)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: "Success!",
											customMessage: `Game activated`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Game could not be activated.",
									});
								});
						}}
					/>
					<ConfirmDialog
						title="Are you sure you want to set reset all the data associated to this game?"
						open={openConfirmDialog === 3}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							resetGameStats(gameId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: "Success!",
											customMessage: `Stats reset successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not reset stats.",
									});
								});
						}}
					>
						{"This will erase all their groups and answers"}
					</ConfirmDialog>
					<ConfirmDialog
						title={`Are you sure you want to ${
							selectedGame?.active ? "end" : "start"
						} this game?`}
						open={openConfirmDialog === 4}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							updateGame(selectedGame._id, {
								...selectedGame,
								active: !selectedGame?.active,
							})
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: "Success!",
											customMessage: !selectedGame?.active
												? "Game Started"
												: "Game Ended",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not update the game.",
									});
								});
						}}
					></ConfirmDialog>
				</CardBody>
			</Card>
			<Snackbar
				open={copiedLink}
				autoHideDuration={6000}
				onClose={() => setCopiedLink(false)}
			>
				<MuiAlert severity="info" sx={{ width: "100%" }}>
					Game link copied
				</MuiAlert>
			</Snackbar>
		</>
	);
}
